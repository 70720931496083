import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { About } from './components/pages/About';
import { ContactsPage } from './components/pages/ContactsPage';
import { ErrorPage } from './components/pages/ErrorPage';
import { Main } from './components/pages/Main';
import { Stub } from './components/pages/Stub';
import { HELP_NAV_ITEMS, NAV_ITEMS } from './constants/navItems';

import './index.css';

const routersComponents = [
  <Main key="main" />,
  <About key="about" />,
  <Stub key="events" />,
  <Stub key="projects" />,
  <Stub key="requests" />,
  <Stub key="reports" />,
  <Stub key="news" />,
  <ContactsPage key="contacts" />,
];

const helpRoutersComponents = [
  <Stub key="can-help" />,
  <Stub key="need-help" />,
];

const router = createBrowserRouter([
  ...routersComponents.map((item, index) => {
    return {
      element: item,
      errorElement: !index ? <ErrorPage /> : null,
      path: NAV_ITEMS[index][0],
    };
  }),
  ...helpRoutersComponents.map((item, index) => {
    return {
      element: item,
      path: HELP_NAV_ITEMS[index][0],
    };
  }),
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
