import { Link } from 'react-router-dom';
import styled from 'styled-components';

// @ts-ignore
import header_logo from '../../../assets/img/logo/header/header_logo.svg';
import { COLORS, SCREEN_WIDTHS } from '../../../constants/styleConstants';

export type TLinkUI = {
  $isSelected: boolean;
};

export const HeaderUI = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 2 * 48px);
    padding: 32px 48px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: calc(100% - 2 * 16px);
    padding: 24px 16px;
    background-color: ${COLORS.blueLight};
  }
`;

export const LogoActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 130px;

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    height: 56px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    background-color: ${COLORS.blueLight};
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 530px;

  @media (max-width: ${SCREEN_WIDTHS.LAPTOP}) {
    width: 400px;
  }

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    flex-direction: row;
    width: 260px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: 240px;
    background-color: ${COLORS.blueLight};
  }
`;

export const HeaderLogo = styled.a`
  width: 100px;
  height: 70px;
  margin-left: 170px;

  background-image: url(${header_logo});
  background-size: cover;

  @media (max-width: ${SCREEN_WIDTHS.LAPTOP}) {
    margin-left: 60px;
  }

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    width: 80px;
    height: 56px;
    margin-left: 0;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    margin-left: 0;
    background-color: ${COLORS.blueLight};
  }
`;

export const Phone = styled.a`
  font-family: 'Ubuntu_light', sans-serif;
  font-size: 22px;
  font-weight: 400;
  font-style: normal;
  line-height: 25px;
  color: ${COLORS.dark};
  text-decoration: none;

  @media (max-width: ${SCREEN_WIDTHS.LAPTOP}) {
    margin-right: 16px;
  }

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    margin-right: 0;

    font-family: 'Ubuntu_light', sans-serif;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    line-height: 21px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    font-size: 16px;
    line-height: 19px;
    background-color: ${COLORS.blueLight};
  }
`;

export const ButtonsContainer = styled.ul`
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  min-width: 770px;
  height: 100%;

  list-style: none;

  background-color: ${COLORS.blueLight};

  //background-color: red;

  @media (max-width: ${SCREEN_WIDTHS.LAPTOP}) {
    min-width: 660px;
  }

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    display: none;
  }
`;

export const LI = styled.li`
  background-color: ${COLORS.blueLight};
`;

export const LinkUI = styled(Link)<TLinkUI>`
  z-index: 10;

  display: flex;
  align-items: center;

  box-sizing: border-box;
  height: 50px;
  padding: 14px 20px;

  font-family: 'Ubuntu_light', sans-serif;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  line-height: 23px;
  color: ${COLORS.dark};
  text-decoration: none;

  background-color: ${COLORS.white};
  border: none;
  border-radius: 50px;

  &:hover {
    cursor: copy;
    color: ${COLORS.white};
    background-color: ${COLORS.blueAccent};
  }
`;
