import React from 'react';

import { CanHelp } from '../../shared/CanHelp';
import { ContactsBox } from '../../shared/ContactsBox';
import { Footer } from '../../shared/Footer';
import { Header } from '../../shared/Header';

import { ContactsPageUI } from './ContactsPage.styles';

export const ContactsPage = () => (
  <ContactsPageUI>
    <Header />
    <ContactsBox />
    <CanHelp />
    <Footer />
  </ContactsPageUI>
);
