// @ts-ignore
import instagram from '../assets/img/logo/footer/instagram.svg';
// @ts-ignore
import instagramHover from '../assets/img/logo/footer/instagram_hover.svg';
// @ts-ignore
import site from '../assets/img/logo/footer/site.svg';
// @ts-ignore
import siteHover from '../assets/img/logo/footer/site_hover.svg';
// @ts-ignore
import telegram from '../assets/img/logo/footer/telegram.svg';
// @ts-ignore
import telegramHover from '../assets/img/logo/footer/telegram_hover.svg';

export const LINKS = [
  {
    id: 'instagram',
    logo: instagram,
    logoHover: instagramHover,
    url: 'https://www.instagram.com/diaconia.by/',
  },
  {
    id: 'telegram',
    logo: telegram,
    logoHover: telegramHover,
    url: 'https://t.me/diaconiaby',
  },
  {
    id: 'site',
    logo: site,
    logoHover: siteHover,
    url: 'https://www.diaconia.by/',
  },
];
