import React from 'react';
import { useRouteError } from 'react-router-dom';

import { Footer } from '../../shared/Footer';
import { Header } from '../../shared/Header';

import { ErrorBtn, ErrorPageUI, ErrorWrapper, Text } from './ErrorPage.styles';

type TError = {
  data: string;
  error: {
    message: string;
    stack: string;
  };
  internal: boolean;

  status: number;
  statusText: string;
};

export const ErrorPage = () => {
  const error = useRouteError() as TError;
  console.error(error);

  return (
    <ErrorPageUI>
      <Header />

      <ErrorWrapper>
        <Text>Что-то пошло не так.</Text>
        <Text>Но мы обязательно это решим.</Text>

        <ErrorBtn to="/">Вернуться на главную</ErrorBtn>
      </ErrorWrapper>

      <Footer />
    </ErrorPageUI>
  );
};
