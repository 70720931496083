import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { COLORS, SCREEN_WIDTHS } from '../../../../constants/styleConstants';
import { TLinkUI } from '../Header.styles';

type TLine = {
  $isOpen: boolean;
};

type TMenu = {
  $isOpen: boolean;
};

export const BurgerMenuUI = styled.div`
  display: none;

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    display: flex;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    background-color: ${COLORS.blueLight};
  }
`;

export const BurgerButton = styled.div`
  z-index: 20;

  display: block;

  width: 44px;
  height: 32px;

  background-color: ${COLORS.blueLight};

  transition: all 0.5s ease-in;

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    margin-left: 16px;
  }
`;

const LineBase = styled.div`
  width: 24px;
  margin: 6px 8px;

  border: 1px solid ${COLORS.dark};
  border-radius: 1px;

  transition: all 0.5s ease-in;
`;

export const LineFirst = styled(LineBase)<TLine>`
  width: ${({ $isOpen }) => ($isOpen ? '30px' : '24px')};
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform-origin: 6px 4px;
      transform: rotate(45deg);
    `}
`;

export const LineMiddle = styled(LineBase)<TLine>`
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      display: none;
      opacity: 0;
    `}
`;

export const LineLast = styled(LineBase)<TLine>`
  width: ${({ $isOpen }) => ($isOpen ? '30px' : '24px')};

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform-origin: 16px 2px;
      transform: rotate(-45deg);
    `}
`;

export const BurgerBar = styled.div<TMenu>`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  transform: ${({ $isOpen }) =>
    $isOpen ? 'translateX(0)' : 'translateX(100%)'};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: calc(100% - 2 * 56px);
  padding: 120px 56px 56px;

  background-color: ${COLORS.blueLight};

  transition: transform 0.3s ease-in-out;

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: calc(100% - 2 * 40px);
    padding: 80px 40px 40px;
  }
`;

export const BurgerUl = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  height: 434px;

  list-style: none;

  background-color: ${COLORS.blueLight};

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    height: 378px;
  }
`;

export const HelpBurgerUl = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 300px;
  height: 152px;
  margin-top: 56px;

  list-style: none;

  background-color: ${COLORS.blueLight};

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: 280px;
    height: 116px;
    margin-top: 32px;
  }
`;

export const LI = styled.li`
  background-color: ${COLORS.blueLight};
`;

export const LinkUI = styled(Link)<TLinkUI>`
  font-family: 'Ubuntu_medium', 'sans-serif';
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  line-height: 28px;
  color: ${COLORS.dark};
  text-decoration: none;

  background-color: ${COLORS.blueLight};

  &:hover {
    color: ${COLORS.white};
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    font-family: 'Ubuntu_medium', 'sans-serif';
    font-size: 18px;
    line-height: 21px;

    &:hover {
      color: ${COLORS.white};
    }
  }
`;

// background-color: ${({ $isSelected }) => $isSelected ? '' : ''};
export const HelpLinkUI = styled(Link)<TLinkUI>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 300px;
  height: 64px;

  font-family: 'Ubuntu_light', 'sans-serif';
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  line-height: 28px;
  color: ${COLORS.dark};
  text-decoration: none;

  background-color: ${COLORS.white};
  border-radius: 50px;

  &:hover {
    color: ${COLORS.white};
    background-color: ${COLORS.blueAccent};
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: 280px;
    height: 50px;
    font-size: 18px;
    line-height: 21px;
  }
`;
