import styled from 'styled-components';

// @ts-ignore
import bgLAPTOP from '../../../../assets/img/bg/main/weHelp/weHelp_1024-1439.png';
// @ts-ignore
import bgDESKTOP from '../../../../assets/img/bg/main/weHelp/weHelp_1440+.png';
// @ts-ignore
import bgPHONE from '../../../../assets/img/bg/main/weHelp/weHelp_320-639.png';
// @ts-ignore
import bgTABLET from '../../../../assets/img/bg/main/weHelp/weHelp_640-1023.png';
import { COLORS, SCREEN_WIDTHS } from '../../../../constants/styleConstants';

type TCatImg = {
  $img: string;
};

export const WeHelpUI = styled.div`
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  width: 100%;
  padding: 150px 170px 0;

  background-image: url(${bgDESKTOP});
  background-repeat: no-repeat;
  background-position: left;
  background-size: 856px 372px;

  @media (max-width: ${SCREEN_WIDTHS.LAPTOP}) {
    background-image: url(${bgLAPTOP});
  }

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    padding: 100px 48px 0;
    background-image: url(${bgTABLET});
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    padding: 80px 0 0;
    background-image: url(${bgPHONE});
    background-size: 456px 372px;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  margin-bottom: 36px;

  font-family: 'Lora_Medium', 'sans-serif';
  font-size: 40px;
  font-weight: 500;
  font-style: normal;
  line-height: 51px;
  color: ${COLORS.dark};

  background: unset;

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    margin-bottom: 40px;
    font-size: 26px;
    line-height: 33px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const SliderUI = styled.div`
  display: none;

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    display: flex;
    margin: 0 auto;
  }
`;

export const CategoriesUI = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;

  width: 100%;
  margin: 0 auto;

  background: unset;

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    display: none;
  }
`;

export const Category = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 200px;
  height: 273px;
  margin-top: 24px;

  background-color: ${COLORS.white};
  border-radius: 16px;
  box-shadow: 0 0 30px rgba(45, 81, 120, 0.1);

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    margin-top: 0;
  }
`;

export const CatImg = styled.div<TCatImg>`
  width: 180px;
  height: 150px;

  background-color: ${COLORS.white};
  background-image: url(${({ $img }) => $img});
  background-size: cover;
`;

export const CatName = styled.p`
  margin-top: 20px;

  font-family: 'Ubuntu_light', 'sans-serif';
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  line-height: 23px;

  background-color: ${COLORS.white};
`;
