import React from 'react';

import { aboutParagraphs } from '../../../../constants/aboutInfo';

import {
  AboutBoxUI,
  AboutWrapper,
  Description,
  Paragraph,
  Photo,
  Title,
} from './AboutBox.styles';

export const AboutBox = () => {
  return (
    <AboutBoxUI>
      <Title>О нас</Title>

      <AboutWrapper>
        <Photo />
        <Description>
          {aboutParagraphs.map((paragraph, index) => (
            <Paragraph key={index}>{paragraph}</Paragraph>
          ))}
        </Description>
      </AboutWrapper>
    </AboutBoxUI>
  );
};
