import React from 'react';

import { CanHelp } from '../../shared/CanHelp';
import { Footer } from '../../shared/Footer';
import { Header } from '../../shared/Header';

import { MainUI } from './Main.styles';
import { TitleBox } from './TitleBox';
import { WeHelp } from './WeHelp';

export const Main = () => (
  <MainUI>
    <Header />
    <TitleBox />
    <WeHelp />
    <CanHelp />
    {/* <div>Projects</div> */}
    {/* <div>Events</div> */}
    {/* <div>Requests</div> */}
    {/* <div>News</div> */}
    <Footer />
  </MainUI>
);
