type TRootRouter = [string, string];

export const NAV_ITEMS: TRootRouter[] = [
  ['/', 'Главная'],
  ['/about', 'О нас'],
  ['/events', 'Акции'],
  ['/projects', 'Проекты'],
  ['/requests', 'Просьбы'],
  ['/reports', 'Отчеты'],
  ['/news', 'Новости'],
  ['/contacts', 'Контакты'],
];

export const HELP_NAV_ITEMS: TRootRouter[] = [
  ['/can-help', 'Как помочь'],
  ['/need-help', 'Нужна помощь'],
];
