import styled from 'styled-components';

import { COLORS, SCREEN_WIDTHS } from '../../../../constants/styleConstants';

type TWayIcon = {
  $img: string;
};

export const HelpWayUI = styled.div`
  display: flex;
  align-items: center;
  width: 334px;
  height: 60px;

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    width: 252px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: 100%;
    border-radius: 16px;
  }
`;

export const WayIcon = styled.div<TWayIcon>`
  width: 63px;
  height: 61px;
  margin-right: 24px;
  background-image: url(${({ $img }) => $img});

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    border-radius: 16px;
  }
`;

export const WayText = styled.p`
  font-family: 'Ubuntu_light', 'sans-serif';
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  line-height: 23px;
  color: ${COLORS.dark};

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    font-size: 18px;
    line-height: 21px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: 204px;
  }
`;
