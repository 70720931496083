export const SCREEN_WIDTHS = {
  DESKTOP: '1919px',
  LAPTOP: '1439px',
  PHONE: '639px',
  TABLET: '1023px',
  add_760: '760px',
  add_1140: '1140px',
  add_1210: '1210px',
};

export const COLORS = {
  blueAccent: '#769FCD',
  blueLight: '#E4EFF9',
  blueNav: '#D6E6F2',
  dark: '#1b262c',
  primary: '#0f0f55',
  test: '#a21515',
  white: '#FFFFFF',
};

// @media (max-width: ${SCREEN_WIDTHS.LAPTOP}) {
// }
//
// @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
// }
//
// @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
// }
