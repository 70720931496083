import React from 'react';

import { HelpWayUI, WayIcon, WayText } from './HelpWay.styles';

type THelpWay = {
  logo: string;
  text: string;
};

export const HelpWay = (props: THelpWay) => {
  const { logo, text } = props;

  return (
    <HelpWayUI>
      <WayIcon $img={logo} />
      <WayText>{text}</WayText>
    </HelpWayUI>
  );
};
