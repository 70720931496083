import styled from 'styled-components';

// @ts-ignore
import bgDESKTOP from '../../../assets/img/bg/contactsBox/bgContactsBox_1024+.png';
// @ts-ignore
import bgTABLET from '../../../assets/img/bg/contactsBox/bgContactsBox_640-1023.png';
// @ts-ignore
import photoDESKTOP from '../../../assets/img/bg/contactsBox/contactsBox_1024+.png';
// @ts-ignore
import photoPHONE from '../../../assets/img/bg/contactsBox/contactsBox_320-639.png';
// @ts-ignore
import photoTABLET from '../../../assets/img/bg/contactsBox/contactsBox_640-1023.png';
import { COLORS, SCREEN_WIDTHS } from '../../../constants/styleConstants';

export const ContactsBoxUI = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-top: 60px;

  background-image: url(${bgDESKTOP});
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 192px 172px;

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    margin-top: 50px;
    background-image: url(${bgTABLET});
    background-size: 88px 123px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    margin-top: 40px;
    background-image: unset;
  }
`;

export const Title = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  font-family: 'Lora_Medium', 'sans-serif';
  font-size: 50px;
  font-weight: 500;
  font-style: normal;
  line-height: 64px;
  color: ${COLORS.dark};

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    font-size: 32px;
    line-height: 38px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  width: 980px;
  height: 460px;
  padding: 24px;

  background-color: ${COLORS.white};
  border-radius: 20px;
  box-shadow: 0 0 30px rgba(45, 81, 120, 0.1);

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    flex-direction: column;

    width: 672px;
    height: 400px;
    padding: 40px 48px;

    background-size: 283px 296px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: unset;
    height: unset;
    padding: unset;
  }
`;

export const ContentData = styled.div`
  display: flex;
  background-color: ${COLORS.white};

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    gap: 24px;
    align-items: flex-start;

    width: 608px;
    height: 270px;
    margin-top: 24px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;

    width: 312px;
    height: 701px;

    background-color: ${COLORS.white};
    border-radius: 20px;
    box-shadow: 0 0 30px rgba(45, 81, 120, 0.1);
  }
`;

export const Photo = styled.div`
  display: flex;

  width: 477px;
  height: 392px;
  margin-right: 40px;

  background-image: url(${photoDESKTOP});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 557px 472px;
  border-radius: 20px;
  box-shadow: 0 0 30px 10px rgba(214, 230, 242, 0.4);

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    width: 328px;
    height: 269px;
    margin-right: 0;

    background-image: url(${photoTABLET});
    background-size: 408px 394px;
    border-radius: 16px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: 264px;
    height: 217px;
    background-image: url(${photoPHONE});
    background-size: 344px 297px;
  }
`;

export const Description = styled.span`
  display: flex;
  flex-direction: column;

  width: 460px;
  height: 392px;

  background-color: ${COLORS.white};

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    width: unset;
    height: unset;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    gap: 16px;
    align-items: flex-start;

    width: 264px;
    height: 412px;

    border-radius: 20px;
  }
`;

export const ContactsWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;

  box-sizing: border-box;
  width: 460px;
  height: 248px;
  margin-top: 32px;

  background-color: ${COLORS.white};

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    gap: 16px;

    width: 256px;
    height: 270px;
    margin-top: 0;

    border-radius: 20px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: 100%;
    height: unset;
    padding: 8px 8px 0;
  }
`;

export const DescriptionTextTABLET = styled.p`
  display: none;

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    display: flex;

    width: 608px;
    height: 42px;

    font-family: 'Ubuntu_medium', 'sans-serif';
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    line-height: 21px;
    color: ${COLORS.dark};

    background-color: ${COLORS.white};
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    display: none;
  }
`;

export const DescriptionText = styled.p`
  font-family: 'Ubuntu_medium', 'sans-serif';
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  line-height: 28px;
  color: ${COLORS.dark};

  background-color: ${COLORS.white};

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    display: none;

    width: 608px;
    height: 42px;

    font-size: 18px;
    line-height: 21px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    display: flex;
    align-items: center;

    width: 264px;
    height: 126px;

    font-size: 18px;
    line-height: 21px;
  }
`;

export const Contact = styled.span`
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 460px;
  height: 50px;

  background-color: ${COLORS.white};

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    width: 256px;
    height: 46px;
  }
`;

export const Name = styled.p`
  font-family: 'Ubuntu_medium', 'sans-serif';
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: 21px;
  color: ${COLORS.dark};

  background-color: ${COLORS.white};

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const Value = styled.p`
  font-family: 'Ubuntu_light', 'sans-serif';
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  line-height: 21px;
  color: ${COLORS.dark};

  background-color: ${COLORS.white};

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    font-size: 16px;
    line-height: 19px;
  }
`;
