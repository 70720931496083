import styled from 'styled-components';

// @ts-ignore
import bgDESKTOP from '../../../assets/img/bg/canHelp/can_help_1024-1439__1440+.png';
// @ts-ignore
import bgPHONE from '../../../assets/img/bg/canHelp/can_help_320-639.png';
// @ts-ignore
import bgTABLET from '../../../assets/img/bg/canHelp/can_help_640-1023.png';
import { COLORS, SCREEN_WIDTHS } from '../../../constants/styleConstants';

export const CanHelpUI = styled.div`
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  width: 100%;
  padding-top: 120px;

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    padding-top: 100px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    padding-top: 80px;
  }
`;

export const Title = styled.p`
  display: flex;
  justify-content: center;

  width: 100%;
  margin-bottom: 60px;

  font-family: 'Lora_Medium', 'sans-serif';
  font-size: 40px;
  font-weight: 500;
  font-style: normal;
  line-height: 51px;
  color: ${COLORS.dark};

  background: unset;

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    margin-bottom: 40px;
    font-size: 26px;
    line-height: 33px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 28px;
  }
`;

export const HelpWaysWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  width: 100%;
  padding: 70px 116px 0;

  background-image: url(${bgDESKTOP});
  background-repeat: no-repeat;
  background-position: top center;

  @media (max-width: ${SCREEN_WIDTHS.add_1210}) {
    padding: 56px 60px 0;
    background-size: 982px 240px;
  }

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    background-image: url(${bgTABLET});
    background-size: 912px 156px;
  }

  @media (max-width: ${SCREEN_WIDTHS.add_760}) {
    background-size: 630px 156px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    padding: 24px 16px 0;
    background-image: url(${bgPHONE});
    background-size: 480px 238px;
  }
`;

export const HelpWaysBoxes = styled.div`
  display: flex;
  justify-content: space-between;
  width: 928px;
  background-color: unset;

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    width: 680px;
  }

  @media (max-width: ${SCREEN_WIDTHS.add_760}) {
    width: 560px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    display: none;
  }
`;

export const HelpWaysBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;
  width: 434px;
  height: 240px;
  padding: 50px;

  border-radius: 16px;
  box-shadow: 0 0 30px rgba(45, 81, 120, 0.1);

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    width: 300px;
    height: 184px;
    padding: 24px;
  }

  @media (max-width: ${SCREEN_WIDTHS.add_760}) {
    width: 280px;
    height: 148px;
    padding: 8px;
  }
`;

export const HelpWaysContainer = styled.div`
  display: none;

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    box-sizing: border-box;
    width: 312px;
    height: 240px;

    border-radius: 16px;
    box-shadow: 0 0 30px rgba(45, 81, 120, 0.1);
  }
`;
