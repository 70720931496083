import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { COLORS, SCREEN_WIDTHS } from '../../../../constants/styleConstants';
import { TLinkUI } from '../Header.styles';

export const NavUI = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 60px;

  background-color: ${COLORS.blueNav};

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    display: none;
  }
`;

export const Ul = styled.ul`
  display: flex;
  justify-content: space-between;

  width: 954px;

  list-style: none;

  background-color: ${COLORS.blueNav};
`;

export const LI = styled.li`
  background-color: ${COLORS.blueNav};
`;

export const NavLink = styled(Link)<TLinkUI>`
  font-family: 'Ubuntu_light', 'sans-serif';
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 21px;
  color: ${COLORS.dark};
  text-decoration: none;

  background-color: ${COLORS.blueNav};
  &:hover {
    color: ${COLORS.white};
  }
`;
