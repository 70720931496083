import { Link } from 'react-router-dom';
import styled from 'styled-components';

// @ts-ignore
import bgDESKTOP from '../../../assets/img/bg/errorPage/error_page_1024+.png';
// @ts-ignore
import bgPHONE from '../../../assets/img/bg/errorPage/error_page_320-639.png';
// @ts-ignore
import bgTABLET from '../../../assets/img/bg/errorPage/error_page_640-1023.png';
import { COLORS, SCREEN_WIDTHS } from '../../../constants/styleConstants';

export const ErrorPageUI = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100vh;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 955px;
  height: 230px;

  background-color: unset;
  background-image: url(${bgDESKTOP});
  background-repeat: no-repeat;
  background-size: 955px 230px;

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    width: 630px;
    height: 162px;

    background-image: url(${bgTABLET});
    background-position: right;
    background-size: 525px 162px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: 310px;
    height: 172px;

    background-image: url(${bgPHONE});
    background-position: right bottom;
    background-size: 310px 172px;
  }
`;

export const Text = styled.p`
  font-family: 'Lora_Medium', 'sans-serif';
  font-size: 40px;
  font-weight: 500;
  font-style: normal;
  line-height: 51px;
  color: ${COLORS.dark};
  text-align: center;

  background: unset;

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    font-size: 26px;
    line-height: 33px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const ErrorBtn = styled(Link)`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  width: 370px;
  height: 68px;
  margin-top: 60px;
  padding: 20px 60px;

  font-family: 'Ubuntu_light', 'sans-serif';
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  line-height: 28px;
  color: ${COLORS.white};
  text-decoration: none;

  background-color: ${COLORS.blueAccent};
  border: none;
  border-radius: 50px;

  &:hover {
    cursor: pointer;
    color: ${COLORS.dark};
    background-color: unset;
    border: 2px solid ${COLORS.blueAccent};
  }

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    width: 273px;
    height: 56px;
    margin-top: 40px;
    padding: 16px 32px;

    font-size: 20px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: 312px;
    height: 50px;
    margin-top: 32px;
    padding: 8px 24px;

    font-size: 18px;
  }
`;
