import { Link } from 'react-router-dom';
import styled from 'styled-components';

// @ts-ignore
import fern_DESKTOP from '../../../../assets/img/bg/main/titleBox/fern_1440+.png';
// @ts-ignore
import handsLAPTOP from '../../../../assets/img/bg/main/titleBox/hands_1024-1439.png';
// @ts-ignore
import handsDESKTOP from '../../../../assets/img/bg/main/titleBox/hands_1440+.png';
// @ts-ignore
import handsPHONE from '../../../../assets/img/bg/main/titleBox/hands_320-639.png';
// @ts-ignore
import handsTABLET from '../../../../assets/img/bg/main/titleBox/hands_640-1023.png';
import { COLORS, SCREEN_WIDTHS } from '../../../../constants/styleConstants';

export const TitleBoxUI = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    overflow: hidden;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    overflow: inherit;
    flex-direction: column-reverse;
  }
`;

export const Container = styled.div`
  margin: 120px 0 0 170px;

  @media (max-width: ${SCREEN_WIDTHS.LAPTOP}) {
    margin-left: 60px;
  }

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    margin: 32px 0 0 48px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    margin: 24px auto 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;

  min-width: 483px;
  min-height: 510px;

  background-image: url(${fern_DESKTOP});
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 157px 140px;

  @media (max-width: 1210px) {
    min-width: 310px;
  }

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    min-height: 350px;
    background-image: unset;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    min-height: auto;
  }
`;

export const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  width: 483px;
  min-height: 364px;

  @media (max-width: ${SCREEN_WIDTHS.add_1210}) {
    width: 400px;
  }

  @media (max-width: ${SCREEN_WIDTHS.add_1140}) {
    width: 310px;
  }

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    min-height: 286px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    min-height: 220px;
  }
`;

export const Title = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  font-family: 'Lora_Medium', 'sans-serif';
  font-size: 50px;
  font-weight: 500;
  font-style: normal;
  line-height: 64px;
  color: ${COLORS.dark};

  @media (max-width: ${SCREEN_WIDTHS.add_1210}) {
    font-size: 32px;
    line-height: 41px;
  }

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    font-size: 32px;
    line-height: 38px;
  }
`;

export const Description = styled.h2`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: 'Ubuntu_light', 'sans-serif';
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  line-height: 28px;
  color: ${COLORS.dark};

  @media (max-width: ${SCREEN_WIDTHS.add_1210}) {
    font-size: 20px;
    line-height: 23px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    font-size: 18px;
    line-height: 21px;
  }
`;

export const MainBtn = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  width: 260px;
  height: 68px;

  font-family: 'Ubuntu_light', 'sans-serif';
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  line-height: 28px;
  color: ${COLORS.white};
  text-decoration: none;

  background-color: ${COLORS.blueAccent};
  border: none;
  border-radius: 50px;

  &:hover {
    cursor: pointer;
    color: ${COLORS.dark};
    background-color: unset;
    border: 2px solid ${COLORS.blueAccent};
  }

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    width: 250px;
    height: 56px;
    font-size: 20px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: 312px;
    height: 50px;
    font-size: 18px;
  }
`;

export const HandsImg = styled.div`
  min-width: 770px;
  height: 645px;

  background-color: ${COLORS.blueLight};
  background-image: url(${handsDESKTOP});
  background-size: cover;

  @media (max-width: ${SCREEN_WIDTHS.LAPTOP}) {
    min-width: 660px;
    background-image: url(${handsLAPTOP});
  }

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    position: absolute;
    top: 0;
    right: 0;

    min-width: 386px;
    height: 506px;

    background-color: ${COLORS.blueLight};
    background-image: url(${handsTABLET});
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 386px 386px;
  }

  @media (max-width: ${SCREEN_WIDTHS.add_760}) {
    min-width: 44%;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    position: static;

    min-width: 100%;
    height: 370px;

    background-image: url(${handsPHONE});
    background-position: center;
    background-size: cover;
  }
`;
