import React from 'react';

import { CONTACTS } from '../../../constants/contacts';

import {
  Contact,
  ContactsBoxUI,
  ContactsWrapper,
  ContentData,
  ContentWrapper,
  Description,
  DescriptionText,
  DescriptionTextTABLET,
  Name,
  Photo,
  Title,
  Value,
} from './ContactsBox.styles';

export const ContactsBox = () => {
  return (
    <ContactsBoxUI>
      <Title>Как с нами связаться</Title>

      <ContentWrapper>
        <DescriptionTextTABLET>
          Синодальный отдел по церковной благотворительности и социальному
          служению Белорусской Православной Церкви
        </DescriptionTextTABLET>

        <ContentData>
          <Photo />
          <Description>
            <DescriptionText>
              Синодальный отдел по церковной благотворительности и социальному
              служению Белорусской Православной Церкви
            </DescriptionText>

            <ContactsWrapper>
              {CONTACTS.map((contact) => (
                <Contact key={contact.id}>
                  <Name>{contact.name}</Name>
                  <Value>{contact.value} </Value>
                </Contact>
              ))}
            </ContactsWrapper>
          </Description>
        </ContentData>
      </ContentWrapper>
    </ContactsBoxUI>
  );
};
