// @ts-ignore
import address from '../assets/img/logo/footer/footer_address.png';
// @ts-ignore
import mail from '../assets/img/logo/footer/footer_letter.png';
// @ts-ignore
import phone from '../assets/img/logo/footer/footer_phone.png';

export const FOOTER_CONTACTS = [
  {
    id: 'phone',
    logo: phone,
    text: '+375 29 710 01 03',
    url: 'tel:+375 29 710 01 03',
  },
  {
    id: 'mail',
    logo: mail,
    text: 'diaconia.by@gmail.com',
    url: 'mailto:diaconia.by@gmail.com',
  },
  {
    id: 'address',
    logo: address,
    text: '220004, Республика Беларусь г. Минск, ул. Раковская, 26, кабинет 1',
    url: 'https://yandex.by/maps/-/CDF-b88f',
  },
];

export const CONTACTS = [
  {
    id: 'address',
    name: 'Адрес:',
    value: 'г.Минск, ул. Раковская, 26, каб.1',
  },
  {
    id: 'e-mail',
    name: 'E-mail:',
    value: 'diaconia.by@gmail.com',
  },
  {
    id: 'phone',
    name: 'Секретарь отдела:',
    value: '+375 29 710 01 03 (Ирина)',
  },
  {
    id: 'mail',
    name: 'Адрес для письменной корреспонденции:',
    value: '220004, г. Минск, ул. Освобождения, 10',
  },
];
