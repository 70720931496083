import React from 'react';

import { CanHelp } from '../../shared/CanHelp';
import { ContactsBox } from '../../shared/ContactsBox';
import { Footer } from '../../shared/Footer';
import { Header } from '../../shared/Header';

import { AboutUI } from './About.styles';
import { AboutBox } from './AboutBox';

export const About = () => (
  <AboutUI>
    <Header />
    <AboutBox />
    <ContactsBox />
    <CanHelp />
    <Footer />
  </AboutUI>
);
