import React from 'react';

import {
  Container,
  Description,
  HandsImg,
  MainBlock,
  MainBtn,
  Title,
  TitleBoxUI,
  Wrapper,
} from './TitleBox.styles';

export const TitleBox = () => {
  return (
    <TitleBoxUI>
      <Container>
        <Wrapper>
          <MainBlock>
            <Title>МИЛОСЕРДИЕ БАЙ</Title>

            <Description>
              Мы являемся православной социальной службой в Беларуси. Наша цель
              — оказать помощь самым нуждающимся и беззащитным.
            </Description>

            <MainBtn to="/can-help">Как помочь</MainBtn>
          </MainBlock>
        </Wrapper>
      </Container>

      <HandsImg />
    </TitleBoxUI>
  );
};
