export const aboutParagraphs: string[] = [
  'Служба помощи «Милосердие» — крупнейшая православная социальная служба в России. Наши 25 проектов расположены в разных частях Москвы, а некоторые программы распространяются на всю страну.',

  'Наша цель — оказать помощь самым нуждающимся и беззащитным: одиноким старикам, людям с инвалидностью, беременным женщинам, оказавшимся без крыши над головой, детям-сиротам, бездомным, людям с ВИЧ-инфекцией.',

  'Одна из ключевых особенностей службы «Милосердие» — наличие собственной инфраструктуры, благодаря которой мы оказываем комплексную, профессиональную и долгосрочную помощь постоянным подопечным. Свято-Софийский социальный дом, Центр реабилитации детей с ДЦП, Елизаветинский детский дом, Свято-Спиридоньевская богадельня, Дом для мамы и многие другие наши проекты – это негосударственные некоммерческие учреждения, которые входят в состав службы «Милосердие».',

  'Их жизнь и работу мы обеспечиваем сами, в основном на средства, перечисляемые нашими жертвователями.',

  'Даже такой большой организации, как наша, не под силу изменить весь мир. Но при вашей поддержке мы ежегодно оказываем помощь не менее 30 000 людей.',

  'Руководитель службы — епископ Верейский Пантелеимон, викарий Святейшего Патриарха Московского и всея Руси, руководитель Синодального отдела по церковной благотворительности и социальному служению.',
];
