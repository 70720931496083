import React from 'react';
import { useLocation } from 'react-router-dom';

import { NAV_ITEMS } from '../../../../constants/navItems';

import { LI, NavLink, NavUI, Ul } from './Navbar.styles';

export const Navbar = () => {
  const location = useLocation();

  return (
    <NavUI>
      <Ul>
        {NAV_ITEMS.map((item) => (
          <LI key={item[0]}>
            <NavLink
              to={item[0]}
              $isSelected={location.pathname === item[0]}
            >
              {item[1]}
            </NavLink>
          </LI>
        ))}
      </Ul>
    </NavUI>
  );
};
