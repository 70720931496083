import React from 'react';

import { FOOTER_CONTACTS } from '../../../constants/contacts';
import { LINKS } from '../../../constants/links';

import {
  ContactContainer,
  ContactsContainer,
  FooterUI,
  LinksContainer,
  Logo,
  Text,
} from './Footer.styles';

export const Footer = () => {
  return (
    <FooterUI>
      <ContactsContainer>
        {FOOTER_CONTACTS.map((el) => (
          <ContactContainer key={el.id}>
            <Logo
              $img={el.logo}
              href={el.url}
            />
            <Text>{el.text}</Text>
          </ContactContainer>
        ))}
      </ContactsContainer>

      <LinksContainer>
        {LINKS.map((el) => (
          <Logo
            key={el.id}
            $img={el.logo}
            $imgHover={el.logoHover}
            href={el.url}
          />
        ))}
      </LinksContainer>
    </FooterUI>
  );
};
