import styled from 'styled-components';

// @ts-ignore
import logo_1990 from '../../../../assets/forStub/1920.png';
import { SCREEN_WIDTHS } from '../../../../constants/styleConstants';

export const ImageWrapper = styled.div`
  width: 1020px;
  height: 557px;
  background-image: url(${logo_1990});
  background-size: cover;

  @media (max-width: ${SCREEN_WIDTHS.DESKTOP}) {
    width: 850px;
    height: 464px;
  }

  @media (max-width: ${SCREEN_WIDTHS.LAPTOP}) {
    width: 760px;
    height: 415px;
  }

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    width: 600px;
    height: 328px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: 280px;
    height: 153px;
  }
`;
