import { Splide, SplideSlide } from '@splidejs/react-splide';
import React from 'react';
import '@splidejs/react-splide/css';

import { CATEGORIES, TCategory } from '../../../../constants/categories';

import {
  CategoriesUI,
  Category,
  CatImg,
  CatName,
  SliderUI,
  Title,
  WeHelpUI,
} from './WeHelp.styles';

export const WeHelp = () => {
  const splideOptions = {
    arrows: false,
    gap: '-75px',
    perMove: 1,
    perPage: 1,
    rewind: true,
  };

  return (
    <WeHelpUI>
      <Title>КОМУ МЫ ПОМОГАЕМ</Title>

      <SliderUI>
        <Splide options={splideOptions}>
          {CATEGORIES.map((cat: TCategory) => (
            <SplideSlide key={cat.id}>
              <Category key={cat.id}>
                <CatImg $img={cat.image} />

                <CatName>{cat.name}</CatName>
              </Category>
            </SplideSlide>
          ))}
        </Splide>
      </SliderUI>

      <CategoriesUI>
        {CATEGORIES.map((cat: TCategory) => (
          <Category key={cat.id}>
            <CatImg $img={cat.image} />

            <CatName>{cat.name}</CatName>
          </Category>
        ))}
      </CategoriesUI>
    </WeHelpUI>
  );
};
