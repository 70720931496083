// @ts-ignore
import institution from '../assets/img/bg/main/weHelp/building.png';
// @ts-ignore
import children from '../assets/img/bg/main/weHelp/children.png';
// @ts-ignore
import elderly from '../assets/img/bg/main/weHelp/elderly.png';
// @ts-ignore
import homeless from '../assets/img/bg/main/weHelp/homeless.png';
// @ts-ignore
import sick from '../assets/img/bg/main/weHelp/sick.png';

export type TCategory = {
  id: number;
  image: string;
  name: string;
};

export const CATEGORIES: TCategory[] = [
  { id: 1, image: elderly, name: 'Пожилым' },
  { id: 2, image: children, name: 'Детям' },
  { id: 3, image: homeless, name: 'Людям в беде' },
  { id: 4, image: sick, name: 'Тяжелобольным' },
  { id: 5, image: institution, name: 'Учреждениям' },
];
