import styled from 'styled-components';

// @ts-ignore
import photoDESKTOP from '../../../../assets/img/bg/about/aboutBox_1024+.png';
// @ts-ignore
import photoPHONE from '../../../../assets/img/bg/about/aboutBox_320-639.png';
// @ts-ignore
import photoTABLET from '../../../../assets/img/bg/about/aboutBox_640-1023.png';
// @ts-ignore
import bgDESKTOP from '../../../../assets/img/bg/about/bgAboutBox_1024+.png';
// @ts-ignore
import bgTABLET from '../../../../assets/img/bg/about/bgAboutBox_640-1023.png';
import { COLORS, SCREEN_WIDTHS } from '../../../../constants/styleConstants';

export const AboutBoxUI = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-top: 60px;

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    margin-top: 50px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    margin-top: 40px;
  }
`;

export const Title = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  font-family: 'Lora_Medium', 'sans-serif';
  font-size: 50px;
  font-weight: 500;
  font-style: normal;
  line-height: 64px;
  color: ${COLORS.dark};

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    font-size: 32px;
    line-height: 38px;
  }
`;

export const AboutWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  width: 100%;
  height: 735px;
  padding: 60px;

  background-image: url(${bgDESKTOP});
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 598px 484px;

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    flex-direction: column;

    height: unset;
    padding: 40px 48px;

    background-image: url(${bgTABLET});
    background-size: 283px 296px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    padding: 24px 8px;
    background-image: unset;
  }
`;

export const Photo = styled.div`
  display: flex;

  width: 512px;
  height: 660px;
  margin-right: 40px;

  background-image: url(${photoDESKTOP});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 572px 720px;
  filter: drop-shadow(0 0 30px rgba(45, 81, 120, 0.1));
  border-radius: 20px;

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    width: 630px;
    height: 426px;
    margin: 0 0 24px;

    background-image: url(${photoTABLET});
    background-size: 732px 486px;
    border-radius: 16px;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: 312px;
    height: 402px;
    background-image: url(${photoPHONE});
    background-size: 372px 462px;
  }
`;

export const Description = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  box-sizing: border-box;
  width: 608px;
  min-height: 660px;
  padding: 16px;

  background-color: ${COLORS.white};
  border-radius: 20px;
  box-shadow: 0 0 30px rgba(45, 81, 120, 0.1);

  @media (max-width: ${SCREEN_WIDTHS.TABLET}) {
    width: 630px;
    min-height: 560px;
    padding: 18px 18px 0;
  }

  @media (max-width: ${SCREEN_WIDTHS.PHONE}) {
    width: 100%;
    height: unset;
    padding: 8px 8px 0;
  }
`;

export const Paragraph = styled.span`
  margin-bottom: 8px;

  font-family: 'Ubuntu_light', 'sans-serif';
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  line-height: 19px;
  color: ${COLORS.dark};
`;
