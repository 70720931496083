import React from 'react';

// @ts-ignore
import donationHelp from '../../../assets/img/logo/canHelp/donationHelp.png';
// @ts-ignore
import foodHelp from '../../../assets/img/logo/canHelp/foodHelp.png';
// @ts-ignore
import infoHelp from '../../../assets/img/logo/canHelp/infoHelp.png';
// @ts-ignore
import volunteerHelp from '../../../assets/img/logo/canHelp/volunteerHelp.png';

import {
  CanHelpUI,
  HelpWaysBox,
  HelpWaysBoxes,
  HelpWaysContainer,
  HelpWaysWrapper,
  Title,
} from './CanHelp.styles';
import { HelpWay } from './HelpWay';

export const CanHelp = () => {
  return (
    <CanHelpUI>
      <Title>КАК МОЖНО ПОМОЧЬ</Title>

      <HelpWaysWrapper>
        <HelpWaysBoxes>
          <HelpWaysBox key="HelpWaysBox_1">
            <HelpWay
              key="HelpWay_1"
              logo={donationHelp}
              text="Сделать пожертвование"
            />
            <HelpWay
              key="HelpWay_2"
              logo={volunteerHelp}
              text="Стать добровольцем"
            />
          </HelpWaysBox>

          <HelpWaysBox key="HelpWaysBox_2">
            <HelpWay
              key="HelpWay_3"
              logo={foodHelp}
              text="Помощь вещами и продуктами"
            />
            <HelpWay
              key="HelpWay_4"
              logo={infoHelp}
              text="Поддержать нас онлайн"
            />
          </HelpWaysBox>
        </HelpWaysBoxes>

        <HelpWaysContainer>
          <HelpWay
            key="HelpWay_1"
            logo={donationHelp}
            text="Сделать пожертвование"
          />
          <HelpWay
            key="HelpWay_2"
            logo={volunteerHelp}
            text="Стать добровольцем"
          />
          <HelpWay
            key="HelpWay_3"
            logo={foodHelp}
            text="Помощь вещами и продуктами"
          />
          <HelpWay
            key="HelpWay_4"
            logo={infoHelp}
            text="Поддержать нас онлайн"
          />
        </HelpWaysContainer>
      </HelpWaysWrapper>
    </CanHelpUI>
  );
};
